<template>
	<div class="dentis">
		<tabBar :cardIndex="3"></tabBar>
		<div class="looking">
		  <div class="lookingname">找到你附近的奥普斯 牙齿美白中心</div>
		  <div class="lookingsher">
		    <input class="left" v-model="parsm.keyWord" @keyup.enter='screen' type="text" placeholder="输入医生姓名、诊所（机构）名称、城市名称搜索诊所"/>
		    <div class="btnsa" @click="screen">搜索</div>
		  </div>
		</div>
		<el-row class="mapads" :class="list.length>0?'actv_map':''" v-if="list.length > 0">
			<img class="map_img" v-if="list.length>6" src="../assets/img/216.png" />
		  <el-col :span="3"><div class="grid-content"></div></el-col>
		  <el-col :span="18">
				<div class="mapadsa">
					<div v-for="(item,index) in list" :key='index' class="dents" @click="detlst(item.id)">
						<img :src="item.remarks" />
						<div class="conts">
							<div>{{item.organizationName}}</div>
							<div>{{item.intro}}</div>
						</div>
					</div>
				</div>
			</el-col>
		  <el-col :span="3"><div class="grid-content bg-purple"></div></el-col>
		</el-row>
		<div v-else class="divesa">
			<img class="images" src="../assets/img/nulls.png" />
			<div class="texts">暂无数据，请更换关键词搜索。</div>
		</div>
		<footers></footers>
	</div>
</template>

<script>
	import tabBar from "@/components/tabBar.vue";
	import triangle from "@/components/triangle.vue";
	import footers from "@/components/footer.vue";
	import { getdenlist } from "@/request/xie";
	export default{
		components:{
			tabBar,
			triangle,
			footers
		},
		data(){
			return{
				parsm:{
					keyWord:"",
				},
				list:[]
			}
		},
		mounted() {
			this.parsm.keyWord = this.$route.query.keyWord
			this.getlist()
		},
		methods:{
			//获取牙医列表
			async getlist(){
				let res=await getdenlist(this.parsm)
				console.log(res)
				this.list=res.result
			},
			//搜索
			screen(){
				this.getlist()
			},
			//跳转牙医详情
			detlst(ids){
				this.$router.push({
					path:'dentaldet',
					query:{id:ids}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.grid-content {
			border-radius: 4px;
			min-height: 36px;
		}
	.dentis{
		.looking {
		  width: 100%;
			height: 743px;
		  position: relative;
			margin-top: 80px;
			background-image: url('../assets/img/xie/yayi.png');
			background-size: 100% 100%;
			overflow: hidden;
		  .lookingname {
		    width: 448px;
		    height: 145px;
		    font-size: 48px;
		    color: #ffffff;
		    margin: 0 auto;
		    margin-top: 170px;
		    text-align: center;
		  }
		  .lookingsher {
		    width: 673px;
		    height: 48px;
		    margin: 0 auto;
		    margin-top: 40px;
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    .left {
		      width: 584px;
		      height: 48px;
		      padding: 16px 30px;
		      font-size: 16px;
		      color: #7d7d7d;
		    }
		    .btnsa {
		      width: 88px;
		      height: 48px;
		      line-height: 48px;
		      text-align: center;
		      background: #1076a8;
		      border: 1px solid #075d87;
		      color: #fffefe;
		    }
		  }
		}
		.mapads {
			.mapadsa{
				display: flex;
				flex-flow: row wrap;
				justify-content: flex-start;
				padding: 0 70px 50px 70px;
				box-sizing: border-box;
			}
			.map_img{
				position: absolute;
				left: 0;
				bottom: 0;
				height: 752px;
			}
			.dents{
				cursor: pointer;
				width: 400px;
				height: 280px;
				position: relative;
				margin-right: 24px;
				margin-bottom: 34px;
				img{
					position: absolute;
					left: 0;
					top: 0;
					z-index: 1;
					width: 100%;
					height: 100%;
					border-radius: 4px 4px 0px 0px;
				}
				.conts{
					position: absolute;
					bottom: 0;
					right: 0;
					z-index: 2;
					width: 100%;
					background-color: rgba(255,255,255,.85);
					padding: 15px 24px 11px 24px;
					box-sizing: border-box;
					color: #535353;
					font-size: 20px;
					div:last-child{
						color: #818181;
						font-size: 14px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
		.actv_map{
			margin-top: -170px;
		}
	}
	.divesa{
		text-align: center;
		margin-bottom: 60px;
		.images{
			width: 360px;
			margin-bottom: 30px;
		}
	}
</style>
